import {NgModule} from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';
import {AuthGuard} from './guards/auth.guard';
import {AutoLoginGuard} from './guards/auto-login.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then(m => m.LoginPageModule),
    canLoad: [AutoLoginGuard]
  },
  {
    path: 'beranda',
    loadChildren: () => import('./pages/dashboard/beranda/beranda.module').then(m => m.BerandaPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'data-siswa',
    loadChildren: () => import('./pages/data-siswa/data-siswa.module').then(m => m.DataSiswaPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'administrasi',
    loadChildren: () => import('./pages/administrasi/administrasi.module').then(m => m.AdministrasiPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'sync',
    loadChildren: () => import('./pages/sync/sync.module').then(m => m.SyncPageModule)
  },
  {
    path: 'edit-profil',
    loadChildren: () => import('./pages/dashboard/edit-profil/edit-profil.module').then(m => m.EditProfilPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'cover-rapor',
    loadChildren: () => import('./pages/rapor/cover-rapor/cover-rapor.module').then(m => m.CoverRaporPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'rapor-tengah-semester',
    loadChildren: () => import('./pages/rapor/rapor-tengah-semester/rapor-tengah-semester.module')
      .then(m => m.RaporTengahSemesterPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'rapor-deskripsi',
    loadChildren: () => import('./pages/rapor/rapor-deskripsi/rapor-deskripsi.module').then(m => m.RaporDeskripsiPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'rapor-angka',
    loadChildren: () => import('./pages/rapor/rapor-angka/rapor-angka.module').then(m => m.RaporAngkaPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'sikap-kls1-spiritual',
    loadChildren: () => import('./pages/penilaian/sikap-kls1-spiritual/sikap-kls1-spiritual.module')
      .then(m => m.SikapKls1SpiritualPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'sikap-kls2-sosial',
    loadChildren: () => import('./pages/penilaian/sikap-kls2-sosial/sikap-kls2-sosial.module').then(m => m.SikapKls2SosialPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'kls3-pengetahuan',
    loadChildren: () => import('./pages/penilaian/kls3-pengetahuan/kls3-pengetahuan.module').then(m => m.Kls3PengetahuanPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'kls4-ketrampilan',
    loadChildren: () => import('./pages/penilaian/kls4-ketrampilan/kls4-ketrampilan.module').then(m => m.Kls4KetrampilanPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'edit-profil',
    loadChildren: () => import('./pages/dashboard/edit-profil/edit-profil.module').then(m => m.EditProfilPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'period',
    loadChildren: () => import('./pages/setting/period/period.module').then(m => m.PeriodPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'kkm-mapel',
    loadChildren: () => import('./pages/setting/kkm-mapel/kkm-mapel.module').then(m => m.KkmMapelPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'konversimapel',
    loadChildren: () => import('./pages/setting/konversimapel/konversimapel.module').then( m => m.KonversimapelPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'setting-rapor',
    loadChildren: () => import('./pages/setting/rapor/setting-rapor.module').then( m => m.SettingRaporPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'rekap-nilai',
    loadChildren: () => import('./pages/rekap-nilai/rekap-nilai.module').then( m => m.RekapNilaiPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'rekap-absensi',
    loadChildren: () => import('./pages/rekap-absensi/rekap-absensi.module').then(m => m.RekapAbsensiPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'sync2online',
    loadChildren: () => import('./pages/sync2online/sync2online.module').then( m => m.Sync2onlinePageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'kegiatan-siswa',
    loadChildren: () => import('./pages/ekstrakulikuler/ekstrakulikuler.module').then( m => m.EkstrakulikulerPageModule)
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {preloadingStrategy: PreloadAllModules})
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
