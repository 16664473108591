import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {map, tap} from 'rxjs/operators';
import {BehaviorSubject, Observable} from 'rxjs';
import {Storage} from '@capacitor/storage';
import {environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  // Init with null to filter out the first value in a guard!
  isAuthenticated: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
  token = '';

  tokenKey = '9ef2188377e8e3575320c2e9dd3b3409836f250d';

  constructor(private http: HttpClient) {
    this.loadToken().then();
  }

  async loadToken() {
    const token = await Storage.get({key: this.tokenKey});
    if (token && token.value) {
      this.token = token.value;
      this.isAuthenticated.next(true);
    } else {
      this.isAuthenticated.next(false);
    }
  }

  login(credentials: { email; password }): Observable<any> {
    const body = {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      grant_type: 'password',
      // eslint-disable-next-line @typescript-eslint/naming-convention
      client_id: environment.clientId,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      client_secret: environment.clientSecret,
      username: credentials.email,
      password: credentials.password
    };
    return this.http.post(`${environment.devUrl}/oauth/token`, body).pipe(
      map((data: any) => data),
      tap(_ => {
        this.isAuthenticated.next(true);
      })
    );
  }

  logout():
    Promise<void> {
    this.isAuthenticated.next(false);

    localStorage.clear();

    return Storage.remove({key: this.tokenKey});
  }

  async getToken(): Promise<any> {
    const storage = await Storage.get({key: this.tokenKey});
    return JSON.parse(storage.value).access_token;
  }
}
