import {Component} from '@angular/core';
import {AuthenticationService} from './services/authentication.service';
import {Router} from '@angular/router';
import {AlertController} from '@ionic/angular';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {

  public pageList = [
    { iconName: 'home-outline', displayText: 'Beranda',  url: '/beranda',},
    { iconName: 'person-circle-outline', displayText: 'Data Siswa',  url: '/data-siswa',},
    {
      iconName: 'document-text-outline', displayText: 'Administrasi', expanded: false, hasChild: true,
      subOptions: [
        {displayText: 'Absensi', url: '/administrasi/absensi'},
        {displayText: 'Kenaikan Kelas', url: '/administrasi/kenaikan'},
      ]
    },
    {
      iconName: 'checkmark-circle-outline', displayText: 'Penilaian', expanded: false, hasChild: true,
      subOptions: [
        {displayText: 'Sikap KI-1 Spiritual', url: '/sikap-kls1-spiritual'},
        {displayText: 'Sikap KI-2 Sosial', url: '/sikap-kls2-sosial'},
        {displayText: 'KI-3 Pengetahuan', url: '/kls3-pengetahuan'},
        {displayText: 'KI-4 Keterampilan', url: '/kls4-ketrampilan'},
        {displayText: 'Kegiatan Siswa', url: '/kegiatan-siswa'},
      ]
    },
    {
      iconName: 'newspaper-outline', displayText: 'Rapor', expanded: false, hasChild: true,
      subOptions: [
        {displayText: 'Cover Rapor', url: '/cover-rapor'},
        {displayText: 'Rapor Tengah Semester', url: '/rapor-tengah-semester'},
        {displayText: 'Rapor Deskripsi', url: '/rapor-deskripsi'},
        {displayText: 'Rapor Angka', url: '/rapor-angka'},
      ]
    },
    {
      iconName: 'document-text-outline', displayText: 'Setting', expanded: false, hasChild: true,
      subOptions: [
        {displayText: 'Konversi Nilai per Mata Pelajaran', url: '/konversimapel'},
        {displayText: 'KKM Mata Pelajaran', url: '/kkm-mapel'},
        {displayText: 'Rapor', url: '/setting-rapor'},
      ]
    },
    {
      iconName: 'book-outline', displayText: 'Rekap', expanded: false, hasChild: true,
      subOptions: [
        {displayText: 'Rekap Nilai', url: '/rekap-nilai'},
        {displayText: 'Rekap Absensi', url: '/rekap-absensi'}
      ]
    },
    { iconName: 'sync-outline', displayText: 'Sinkronisasi',  url: '/sync2online'}
  ];

  constructor(private authService: AuthenticationService, private router: Router, public alertController: AlertController) {
  }

  async logout() {
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: 'Apakah Anda Yakin Ingin Keluar?',
      message: 'Pastikan Anda sudah melakukan proses sinkronisasi sebelum melakukan logout, ' +
        'data yang belum di sinkronisasi akan dihapus setelah proses logout selesai.',
      buttons: [
        {
          text: 'Batalkan',
          role: 'cancel',
          cssClass: 'secondary',
          id: 'cancel-button',
        }, {
          text: 'Keluar',
          id: 'confirm-button',
          handler: async () => {
            await this.authService.logout();
            await this.router.navigateByUrl('/', {replaceUrl: true});
          }
        }
      ]
    });

    await alert.present();
  }
}
